import React from 'react';

import Icon from '../icons/icon';

import styles from './instagram-title.module.scss';

// A custom title component for the Instagram slider.
const InstagramTitle = () => {
  return (
    <React.Fragment>
      <h2 className={styles.title}>Follow us on Instagram @VisitCitySprings</h2>
      <h2 className={styles.smallTitle}>
        <span className={styles.smallTitleTop}>
          <Icon
            icon="instagram"
            aria-hidden="true"
            className={styles.icon}
            width="45"
            height="45"
          />
          Instagram
        </span>
        <span className={styles.smallTitleBottom}>@VisitCitySprings</span>
      </h2>
    </React.Fragment>
  );
};

export default InstagramTitle;

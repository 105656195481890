import React from 'react';
import { graphql } from 'gatsby';

import Button from '../components/button/button';
import CTABand from '../components/cta-band/cta-band';
import DefaultLayout from '../components/default-layout/default-layout';
import InstagramSlide from '../components/instagram-slide/instagram-slide';
import InstagramTitle from '../components/instagram-title/instagram-title';
import MediaObject from '../components/media-object/media-object';
import Slide from '../components/slide/slide';
import Slideshow from '../components/slideshow/slideshow';
import SocialLinks from '../components/social-links/social-links';

// Data
import slides from '../../data/explore-slideshow.json';
import instagram from '../../data/instagram-slideshow.json';

const StyleguidePage = ({ data }) => (
  <DefaultLayout data={data}>
    <div style={{ maxWidth: '80ch', margin: '0 auto', width: '90%' }}>
      <h1 className="heading-1">City Springs</h1>
      <hr />
      <h2 className="heading-2">Headings</h2>
      <h1 className="heading-1">Heading 1</h1>
      <h2 className="heading-2">Heading 2</h2>
      <h3 className="heading-3">Heading 3</h3>
      <h4 className="heading-4">Heading 4</h4>
      <h5 className="heading-5">Heading 5</h5>
      <h6 className="heading-6">Heading 6</h6>
      <hr />

      <h2 className="heading-2">Font Families</h2>

      <h3 className="heading-3" style={{ fontFamily: 'Open Sans' }}>
        Open Sans
      </h3>
      <h4
        className="heading-4"
        style={{ fontFamily: 'Open Sans', fontWeight: '300' }}
      >
        Light (font-weight: 300)
      </h4>
      <p style={{ fontFamily: 'Open Sans', fontWeight: '300' }}>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        <em> Nihil quia quae facere cumque quam praesentium.</em> Hic porro
        aliquam minus a iste eligendi expedita, architecto alias, unde earum
        possimus qui cum.
      </p>
      <br />
      <h4
        className="heading-4"
        style={{ fontFamily: 'Open Sans', fontWeight: 'normal' }}
      >
        Regular
      </h4>
      <p style={{ fontFamily: 'Open Sans' }}>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        <em> Nihil quia quae facere cumque quam praesentium.</em> Hic porro
        aliquam minus a iste eligendi expedita, architecto alias, unde earum
        possimus qui cum.
      </p>
      <br />
      <h4
        className="heading-4"
        style={{ fontFamily: 'Open Sans', fontWeight: 'bold' }}
      >
        Bold
      </h4>
      <p style={{ fontFamily: 'Open Sans', fontWeight: 'bold' }}>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        <em> Nihil quia quae facere cumque quam praesentium.</em> Hic porro
        aliquam minus a iste eligendi expedita, architecto alias, unde earum
        possimus qui cum.
      </p>
      <br />
      <h4
        className="heading-4"
        style={{ fontFamily: 'Open Sans', fontWeight: '900' }}
      >
        Black (font-weight: 900)
      </h4>
      <p style={{ fontFamily: 'Open Sans', fontWeight: '900' }}>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        <em> Nihil quia quae facere cumque quam praesentium.</em> Hic porro
        aliquam minus a iste eligendi expedita, architecto alias, unde earum
        possimus qui cum.
      </p>
      <br />
      <h3 className="heading-3" style={{ fontFamily: 'Brauer Neue' }}>
        Brauer Neue
      </h3>
      <h4
        className="heading-4"
        style={{ fontFamily: 'Brauer Neue', fontWeight: 'normal' }}
      >
        Regular
      </h4>
      <p style={{ fontFamily: 'Brauer Neue' }}>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        <em> Nihil quia quae facere cumque quam praesentium.</em> Hic porro
        aliquam minus a iste eligendi expedita, architecto alias, unde earum
        possimus qui cum.
      </p>
      <br />
      <h4
        className="heading-4"
        style={{ fontFamily: 'Brauer Neue', fontWeight: 'bold' }}
      >
        Bold
      </h4>
      <p style={{ fontFamily: 'Brauer Neue', fontWeight: 'bold' }}>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        <em> Nihil quia quae facere cumque quam praesentium.</em> Hic porro
        aliquam minus a iste eligendi expedita, architecto alias, unde earum
        possimus qui cum.
      </p>
      <br />
      <h4
        className="heading-4"
        style={{ fontFamily: 'Brauer Neue', fontWeight: '900' }}
      >
        Black (font-weight: 900)
      </h4>
      <p style={{ fontFamily: 'Brauer Neue', fontWeight: '900' }}>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        <em> Nihil quia quae facere cumque quam praesentium.</em> Hic porro
        aliquam minus a iste eligendi expedita, architecto alias, unde earum
        possimus qui cum.
      </p>
      <br />

      <hr />
      <h2 className="heading-2">Buttons</h2>
      <Button>Default button</Button>
      <br />
      <Button to="/page-2">A Link Button</Button>
      <br />
      <Button size="small">Small button</Button>
      <br />
      <Button size="large">Large button</Button>
      <br />
      <Button color="blueMedium">Button that is blueMedium</Button>
      <br />
      <Button color="white">White Button</Button>
      <br />
      <Button textStyle="upper">Uppercase button</Button>
      <br />
      <Button>
        <h2>Some HTML</h2>
        <span>in a Button</span>
        <span>✈️</span>
      </Button>
      <br />
      <Button borderStyle="square">Square Corners</Button>
      <br />
      <Button disabled="disabled">Disabled button</Button>
      <br />
      <Button size="small" disabled="disabled" color="blueMedium">
        Disabled blueMedium button small
      </Button>
      <br />
      <hr />
      <h2 className="heading-2">Social Links</h2>
      <SocialLinks />
      <br />
      <hr />
      <h2 className="heading-2">Media Object</h2>
      <MediaObject
        src="https://via.placeholder.com/60x60"
        alt="Placeholder content"
        to="/page-2"
      >
        <p>
          <strong>AN EVENING WITH GREGORY PORTER</strong>
        </p>
        <p>
          <small>
            <i>Showing: 06/15 - 06/18</i>
          </small>
        </p>
      </MediaObject>
      <MediaObject
        src="https://via.placeholder.com/60x60"
        alt=""
        className="yolo"
      >
        <p>You can put whatever you want in this mediaObject.</p>
        <p>You can also add a link and pass in a class name if you need to.</p>
      </MediaObject>
    </div>
    <Title title="CTA Band" />
    <CTABand
      title="Don't Miss a Once in a Lifetime Experience"
      to="/insider"
      text="Sign Up to Receive Emails about Upcoming Events"
    />
    <Title title="Slideshow" />
    <Slideshow title="Explore City Springs">
      {slides.slides.map((slide, index) => (
        <Slide key={index} {...slide} />
      ))}
    </Slideshow>
    <Title title="Instagram Slider" />
    <Slideshow title={<InstagramTitle />}>
      {instagram.slides.map((slide, index) => (
        <InstagramSlide key={index} {...slide} />
      ))}
    </Slideshow>
  </DefaultLayout>
);

const Title = ({ title }) => (
  <div style={{ maxWidth: '80ch', margin: '0 auto', width: '90%' }}>
    <br />
    <hr />
    <h2 className="heading-2">{title}</h2>
  </div>
);

export default StyleguidePage;

export const query = graphql`
  query StyleGuidePageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;

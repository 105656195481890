import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icons/icon';
import styles from './instagram-slide.module.scss';

const InstagramSlide = ({ text, displayUrl }) => (
  <div className={styles.slide}>
    <div className={styles.imgContainer}>
      <Icon
        aria-hidden="true"
        className={styles.icon}
        height="15"
        icon="instagram"
        width="15"
      />
      <img className={styles.img} src={displayUrl} alt={text} />
    </div>
    <p aria-hidden="true" className={styles.body}>
      {text}
    </p>
  </div>
);

InstagramSlide.propTypes = {
  text: PropTypes.string.isRequired,
  displayUrl: PropTypes.string.isRequired
};

export default InstagramSlide;
